@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: ARLRDBD;
  src: url('./assets/ARLRDBD.ttf');
}

.font-ARLRDBD {
  font-family: ARLRDBD;
}

@font-face {
  font-family: 'ROG LyonsType Regular';
  font-style: normal;
  font-weight: normal;
  src: local('ROG LyonsType Regular'), url('./assets/ROGLyonsTypeRegular3.woff') format('woff');
}

.font-ROGFONT {
  font-family: 'ROG LyonsType Regular';
}

/* .amount {
  color: red;
  border: none;
} */

circle {
  background-image: url('./assets/bg.png');
  /* fill: rgb(101, 54, 163);
  fill-opacity: .4 !important;
  stroke-width: 1.5px;
  cursor: pointer;
  stroke: rgb(101, 54, 163);
  stroke-dasharray: none; */
}


circle:hover {
  fill: rgb(101, 54, 163) !important;
  fill-opacity: 1 !important;
  stroke-width: 3px;
  cursor: pointer;
  fill: rgb(101, 54, 163);
  stroke: rgb(255, 255, 255) !important;
  stroke-dasharray: none;
}

circle:active {
  fill: rgb(101, 54, 163) !important;
  fill-opacity: 1 !important;
  cursor: pointer;
  fill: rgb(101, 54, 163);
  stroke: rgb(255, 255, 255) !important;
  stroke-dasharray: none;
}

.item-active {
  fill: rgb(101, 54, 163) !important;
  fill-opacity: 1 !important;
  stroke-width: 3px;
  cursor: pointer;
  fill: rgb(101, 54, 163);
  stroke: rgb(255, 255, 255) !important;
  stroke-dasharray: none;
}